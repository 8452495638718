import React, {FC, useEffect} from "react";
import ReactModal, {OnAfterOpenCallback} from "react-modal";
import {css, SerializedStyles, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {rgba} from "emotion-rgba";

export type ICoreModalProps = {
    children: React.ReactNode;
    isOpen: boolean;
    onModalClose?: onModalClose;
    closeButton?: (closeModal: onModalClose) => JSX.Element;
    contentStyle?: SerializedStyles | SerializedStyles[];
    overlayStyle?: SerializedStyles | SerializedStyles[] | ((theme: Theme) => SerializedStyles);
    shouldCloseOnOverlayClick?: boolean;
    closeTimeout?: number;
    contentLabel?: string;
    modalRootId?: string;
    showCloseButton?: boolean;
    //  TODO: After rewrite to rp2, analyze if className props are needed
    className?: string;
    overlayClassName?: string;
    onAfterOpen?: OnAfterOpenCallback;
    onAfterClose?: () => void;
};

type onModalClose = (event: React.MouseEvent | React.KeyboardEvent) => void;

export const ModalCore: FC<ICoreModalProps> = (props) => {
    const {
        children,
        isOpen,
        onModalClose,
        closeTimeout,
        contentLabel,
        modalRootId,
        shouldCloseOnOverlayClick,
        contentStyle,
        overlayStyle,
        overlayClassName,
        className,
        closeButton,
        onAfterClose,
        onAfterOpen,
        showCloseButton = true
    } = props;

    useEffect(() => {
        if (process.env.EXEC_ENV === "browser") {
            const rootId = modalRootId ? modalRootId : "#root";
            ReactModal.setAppElement(rootId);
        }
    }, [modalRootId]);

    const closeModal = (event: React.MouseEvent | React.KeyboardEvent) => {
        if (onModalClose) {
            onModalClose(event);
        }
    };

    return (
        <ReactModal
            closeTimeoutMS={!!closeTimeout ? closeTimeout : 200}
            isOpen={isOpen}
            ariaHideApp={false}
            contentLabel={contentLabel}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            className={className}
            contentElement={
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({style, ...propsWithoutStyle}, children) => (
                    <div {...propsWithoutStyle} css={[defaultContentStyle, contentStyle]}>
                        {children}
                    </div>
                )
            }
            overlayElement={
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({style, ...propsWithoutStyle}, children) => (
                    <Overlay {...propsWithoutStyle} className={overlayClassName} css={overlayStyle}>
                        {children}
                    </Overlay>
                )
            }
            onRequestClose={closeModal}
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
        >
            <div css={childrenHolder}>
                {children}
                {showCloseButton && closeButton && closeButton(closeModal)}
            </div>
        </ReactModal>
    );
};

//  Styles

// Based on default react-modal styles, but used as emotion SerializedStyles (className)
// instead of inline style (harder to override)
const defaultContentStyle = css`
    position: absolute;
    background: #ffffff;
    overflow: auto;
    border-radius: 1.6rem;
    outline: none;
    padding: 2rem;
`;

const Overlay = styled.div`
    position: fixed;
    inset: 0;
    background-color: ${({theme}) => rgba(theme.colors.secondary, 0.75)};
`;

const childrenHolder = css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 100%;
    height: 100%;
`;
